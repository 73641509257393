import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4 text-pink-600">プライバシーポリシー</h1>
      <p className="mb-4">
        おむつ価格ランキングサイト（以下、「当サイト」）は、ユーザーの個人情報保護を重要視しています。
        当サイトのプライバシーポリシーは以下の通りです。
      </p>
      <h2 className="text-xl font-semibold mt-4 mb-2">1. 収集する情報</h2>
      <p>
        当サイトでは、ユーザーが自発的に提供した情報のみを収集します。
        これには、問い合わせフォームを通じて提供される名前やメールアドレスなどが含まれます。
      </p>
      <h2 className="text-xl font-semibold mt-4 mb-2">2. 情報の利用目的</h2>
      <p>
        収集した情報は、ユーザーからの問い合わせに対応するためにのみ使用されます。
        当サイトは、ユーザーの同意なしに個人情報を第三者と共有することはありません。
      </p>
      <h2 className="text-xl font-semibold mt-4 mb-2">3. Cookieの使用</h2>
      <p>
        当サイトでは、ユーザーエクスペリエンスの向上のためにCookieを使用することがあります。
        ユーザーはブラウザの設定でCookieを無効にすることができます。
      </p>
      <h2 className="text-xl font-semibold mt-4 mb-2">4. 情報の保護</h2>
      <p>
        当サイトは、収集した個人情報を適切に保護するために、セキュリティ対策を講じています。
        ただし、インターネット上での完全なセキュリティを保証することはできません。
      </p>
      <h2 className="text-xl font-semibold mt-4 mb-2">5. 外部リンク</h2>
      <p>
        当サイトには、外部ウェブサイトへのリンクが含まれる場合があります。
        外部サイトのプライバシーポリシーやコンテンツについては、当サイトは責任を負いません。
      </p>
      <h2 className="text-xl font-semibold mt-4 mb-2">6. プライバシーポリシーの変更</h2>
      <p>
        当サイトは、必要に応じてプライバシーポリシーを変更することがあります。
        重要な変更がある場合は、サイト上で通知します。
      </p>
      <h2 className="text-xl font-semibold mt-4 mb-2">7. お問い合わせ</h2>
      <p>
        プライバシーポリシーに関するご質問やご意見がある場合は、お問い合わせフォームからご連絡ください。
      </p>
    </div>
  );
};

export default PrivacyPolicy;