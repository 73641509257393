export const fetchDiapers = async (brand, size) => {
  // ここにおむつデータを取得するロジックを実装してください
  // 仮のデータを返すダミー関数の例：
  return [
    { ASIN: '123', Title: 'サンプルおむつ1', Brand: brand, Size: size, Count: 50, Price: 2000 },
    { ASIN: '456', Title: 'サンプルおむつ2', Brand: brand, Size: size, Count: 60, Price: 2400 },
  ];
};

export const getAmazonLink = (asin) => {
  return `https://www.amazon.co.jp/dp/${asin}?tag=tofudeal2025-22`;
};

export const getPriceInfo = (diaper) => {
  const price = diaper.Price;
  const pricePerUnit = price / diaper.Count;
  return {
    price,
    pricePerUnit,
    isSubscribe: false,
    discountRate: 0,
  };
};
