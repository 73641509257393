import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FaBaby, FaSearch, FaArrowUp } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';

import HomePage from './components/HomePage';
import PrivacyPolicy from './components/PrivacyPolicy';
import About from './components/About';
import Sitemap from './components/Sitemap';
import { getAmazonLink, getPriceInfo } from './utils/dataUtils';
import IOSInstallPrompt from './components/IOSInstallPrompt';
import Header from './components/Header';
import { OneSignalInitial } from './lib/OneSignalInitial';

const App = () => {
  const [diapers, setDiapers] = useState([]);
  const [brand, setBrand] = useState('');
  const [size, setSize] = useState('M');
  const [type, setType] = useState(''); // デフォルトを空文字列に設定
  const [loading, setLoading] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);

  const brands = [
    'パンパース',
    'メリーズ',
    'ムーニー',
    'グーン',
    'GENKI',
    'マミーポコ',
    'オヤスミマン',
    'トレパンマン',
    'Mama Bear',
  ];

  const sizes = [
    '新生児', 'S', 'M', 'L', 'ビッグ', 'ビッグより大きい'
  ];

  const types = ['パンツ', 'テープ'];

  const fetchDiapers = async (brand, size, type) => {
    setLoading(true);
    try {
      // const response = await fetch(`http://127.0.0.1:30081/api/diapers?brand=${encodeURIComponent(brand)}&size=${encodeURIComponent(size)}&type=${encodeURIComponent(type)}`);
      const response = await fetch(`https://deal-hunter.net:30081/api/diapers?brand=${encodeURIComponent(brand)}&size=${encodeURIComponent(size)}&type=${encodeURIComponent(type)}`);
      const data = await response.json();

      const sortedData = data.sort((a, b) => {
        const priceA = getPriceInfo(a).pricePerUnit;
        const priceB = getPriceInfo(b).pricePerUnit;
        return priceA - priceB;
      });
      setDiapers(sortedData);
    } catch (error) {
      console.error('データ取得エラー:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (size === '新生児') {
      setType('');
    }
    fetchDiapers(brand, size, type);
  }, [brand, size, type]);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.pageYOffset > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
 

  const handleBrandChange = (selectedBrand) => {
    setBrand(selectedBrand);
  };

  const handleSizeChange = (selectedSize) => {
    setSize(selectedSize);
  };

  const handleTypeChange = (selectedType) => {
    setType(selectedType);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Router>
      <HelmetProvider>
        <div className="w-full min-h-screen flex flex-col bg-gradient-to-b from-pink-50 to-white">
          <Helmet>
            <title>おむつ 価格ランキング</title>
            <meta name="description" content="おむつの価格情報を比較して最安値を見つけることができるサイトです。" />
            <meta name="keywords" content="おむつ, 価格比較, ベビー用品, ランキング, 子育て, パパママ応援" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          </Helmet>

          <Header />
          <OneSignalInitial />

          <main className="flex-grow container mx-auto p-4 md:p-6">
            <Routes>
              <Route path="/" element={
                <HomePage
                  brands={brands}
                  sizes={sizes}
                  types={types}
                  brand={brand}
                  size={size}
                  type={type}
                  handleBrandChange={handleBrandChange}
                  handleSizeChange={handleSizeChange}
                  handleTypeChange={handleTypeChange}
                  loading={loading}
                  diapers={diapers}
                  getPriceInfo={getPriceInfo}
                  getAmazonLink={getAmazonLink}
                />
              } />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/about" element={<About />} />
              <Route path="/sitemap" element={<Sitemap />} />
            </Routes>
          </main>

          <footer className="bg-custom-green-800 text-white py-4 mt-8">
            <div className="container mx-auto text-center">
              <div className="flex flex-wrap justify-center space-x-4 mb-4">
                <Link to="/privacy-policy" className="hover:underline">プライバシーポリシー</Link>
                <Link to="/about" className="hover:underline">運営者情報</Link>
                <Link to="/sitemap" className="hover:underline">サイトマップ</Link>
                <a href="https://x.com/dealhunter72717" target="_blank" rel="noopener noreferrer" className="hover:underline">公式X（旧Twitter）</a>
              </div>
              <p className="text-sm md:text-base flex items-center justify-center mb-2">
                <FaBaby className="mr-2" /> © 2024 おむつ価格ランキング. All rights reserved.
              </p>
              <p className="text-xs">
                当サイトは、Amazonアソシエイトプログラムの参加者です。
              </p>
              <p className="text-xs mt-2">
                最新情報は<a href="https://x.com/dealhunter72717" target="_blank" rel="noopener noreferrer" className="text-custom-green-300 hover:underline font-bold">公式X</a>でも発信しています。
              </p>
            </div>
          </footer>

          <AnimatePresence>
            {showScrollTop && (
              <motion.button
                aria-label="ページトップへスクロール"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                onClick={scrollToTop}
                className="fixed bottom-4 right-4 bg-custom-green-500 text-white p-3 rounded-full shadow-lg hover:bg-custom-green-600 transition-all duration-200"
              >
                <FaArrowUp />
              </motion.button>
            )}
          </AnimatePresence>
          <IOSInstallPrompt />
        </div>
      </HelmetProvider>
    </Router>
  );
}

export default App;