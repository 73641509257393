import React, { useState, useEffect } from 'react';

const IOSInstallPrompt = () => {
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isStandalone = window.navigator.standalone;
    
    if (isIOS && !isStandalone) {
      setShowPrompt(true);
    }
  }, []);

  if (!showPrompt) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm w-full mx-4">
        <h2 className="text-2xl font-bold mb-4 text-indigo-700">
          より快適に利用するには
        </h2>
        <p className="text-gray-700 mb-4">
          ホーム画面に追加し、通知を許可することで、最新のおむつ価格情報をいち早くお届けします！
        </p>
        <ol className="text-sm text-gray-600 list-decimal list-inside mb-4">
          <li>下部の共有ボタン<span className="inline-block w-5 h-5 bg-blue-500 text-white text-center rounded">↑</span>をタップ</li>
          <li>「ホーム画面に追加」を選択</li>
          <li>右上の「追加」をタップ</li>
        </ol>
        <button
          className="w-full bg-indigo-600 text-white py-2 rounded-lg font-bold hover:bg-indigo-700 transition-colors"
          onClick={() => setShowPrompt(false)}
        >
          閉じる
        </button>
      </div>
    </div>
  );
};

export default IOSInstallPrompt;
